import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import classNames from "classnames";
import Input from "../components/elements/Input";
import Button from "../components/elements/Button";
import Select from "../components/elements/Select";
import axios from "axios";
import toast from "react-hot-toast";
import { formatUrlWithAffiliateCode } from "../utils/utils";

const Database = () => {
  const [itemName, setItemName] = React.useState("");
  const [color, setColor] = React.useState("");
  const [lemonDrops, setLemonDrops] = React.useState([]);
  const [country, setCountry] = React.useState("USD");
  const [isLoading, setIsLoading] = React.useState(false);

  const outerClasses = classNames("hero section");

  async function search() {
    try {
      setIsLoading(true);
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API}/search`,
        data: {
          itemName,
          color,
          currency: country,
        },
      });
      setLemonDrops(res?.data);
    } catch (error) {
      console.log("error at search", error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function copyUrl(lemonDrop) {
    await navigator.clipboard.writeText(lemonDrop.url);
    toast.success("Copied to clipboard!");
  }

  function displayRestockedDate(lemonDrop) {
    const updatedAtTime = new Date(lemonDrop.updatedAt).getTime();
    const createdAtTime = new Date(lemonDrop.createdAt).getTime();
    if (updatedAtTime === createdAtTime) {
      return "N/A";
    }
    return new Date(lemonDrop?.updatedAt).toLocaleDateString("en-US");
  }

  return (
    <section className={outerClasses}>
      <div className="container">
        <h1>Search Database</h1>
        <p>
          Use this page to search for links to lululemon product that isn't
          available on lululemon's site.
        </p>

        <div>
          <Input
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            label="Product name"
            placeholder={"Everywhere Belt Bag"}
          />
          <Input
            value={color}
            onChange={(e) => setColor(e.target.value)}
            label="Color"
            placeholder={"White Opal"}
          />
          <Select
            onChange={(e) => setCountry(e.target.value)}
            value={country}
            label="Select Country"
          >
            <option value="USD">US</option>
            <option value="CAD">Canada</option>
          </Select>
          <Button
            loading={isLoading}
            onClick={search}
            color="dark"
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            Search
          </Button>

          <div>
            {lemonDrops?.length === 0 ? <p>No results</p> : <div></div>}
            <Table>
              <Thead>
                <Tr>
                  <Th>Product Name</Th>
                  <Th>Color</Th>
                  <Th>Size</Th>
                  <Th>Price</Th>
                  <Th>Watch Type</Th>
                  <Th>Country</Th>
                  <Th>Restocked</Th>
                </Tr>
              </Thead>
              <Tbody>
                {lemonDrops?.map((lemonDrop) => {
                  return (
                    <Tr key={lemonDrop?.lemonDropId}>
                      <Td>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={formatUrlWithAffiliateCode(lemonDrop.url)}
                        >
                          {lemonDrop?.itemName}
                        </a>
                      </Td>
                      <Td>{lemonDrop?.color}</Td>
                      <Td>{lemonDrop?.size}</Td>
                      <Td>${lemonDrop?.price}</Td>
                      <Td>
                        {lemonDrop?.watchType === "OOS"
                          ? "Restock"
                          : "Price drop"}
                      </Td>
                      <Td>
                        {lemonDrop?.currency === "USD" ? (
                          <span role={"img"} aria-label="emoji">
                            🇺🇸
                          </span>
                        ) : (
                          <span role={"img"} aria-label="emoji">
                            🇨🇦
                          </span>
                        )}
                      </Td>
                      <Td>{displayRestockedDate(lemonDrop)}</Td>
                      <Td>
                        <Button
                          color={"dark"}
                          onClick={() => copyUrl(lemonDrop)}
                        >
                          Copy URL
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </div>
        </div>
      </div>
    </section>
  );
};

Database.propTypes = {};

Database.propTypes = {};

export default Database;
