import React from "react";
import GenericSection from "../../components/sections/GenericSection";
import SectionHeader from "../../components/sections/partials/SectionHeader";
import Accordion from "../../components/elements/Accordion";
import AccordionItem from "../../components/elements/AccordionItem";
import { Link } from "react-router-dom";

function FAQ() {
  return (
    <GenericSection topDivider className="illustration-section-06">
      <div className="container-xs">
        <SectionHeader data={{ title: "FAQ's" }} className="center-content" />
        <Accordion>
          <AccordionItem title="When does lululemon restock?" active>
            Usually Tuesdays at 2pm US/Eastern time. But ultimately, they could
            restock at any time, that's why we created an alert system! Sign up
            to get a text as soon as your item is back in stock.
          </AccordionItem>
          <AccordionItem title="When does lululemon restock online?">
            Usually Tuesdays and Thursdays at 2pm US/Eastern time. But
            ultimately, they could restock at any time, that's why we created an
            alert system! Sign up to get a text as soon as your item is back in
            stock.
          </AccordionItem>
          <AccordionItem title="Are your alerts faster than lululemon?">
            You bet your sweet bippy we are. We check more often than lululemon
            and send you a text when an item restocks, which is faster than
            lululemon's emails, and the messages don't get stuck in your spam or
            promotions folder.
          </AccordionItem>
          {/* <AccordionItem title="Do you offer a money back guarantee?">
            Yes! We sure do. If you are at all dissatisfied with our service we
            will give you your money back. Just send a message in the chat in
            the lower right of the screen, or email us at
            getlululemondrops@gmail.com (chat is faster).
          </AccordionItem> */}
          <AccordionItem title="What time does lululemon restock online?">
            Usually Tuesdays at 2pm US/Eastern time. But ultimately, they could
            restock at any time, that's why we created an alert system! Sign up
            to get a text as soon as your item is back in stock.
          </AccordionItem>
          <AccordionItem title="Why did my alerts go away!?">
            After we text you once, that item gets moved to "Past". If you
            weren't able to purchase the item, you'll have to rewatch it. This
            is because we check every 2 mins if something is in stock, so if
            your item is in stock for 2 hours, we will send you 60 messages! 1
            every 2 minutes.
          </AccordionItem>
          <AccordionItem title="Is lululemonDrops legit?">
            Yup! We sure are! There have been thousands of successful purchases
            from our alerts. If you are at all dissatisfied with our service we
            will give you your money back. Just send a message in the chat in
            the lower right of the screen, or email us at
            getlululemondrops@gmail.com (chat is faster).
          </AccordionItem>
          <AccordionItem title="What phone number will the alerts come from?">
            +1 (920) 332-5503
          </AccordionItem>
          <AccordionItem title="I got a text that an item was in stock, but the lululemon website says it's out of stock.">
            Try the lululemon app! For some reason the app updates quicker than
            the lululemon website.
          </AccordionItem>
          <AccordionItem title="How often do you check if my item is in stock?">
            Our bot runs every 2 minutes to ensure you find out as soon as
            possible when your item is back in stock. That's also why we use
            text messages to notify you as well, because texts get delivered
            quicker than emails.
          </AccordionItem>
          <AccordionItem title="What phone number are alerts sent from?">
            +1 (920) 332-5503 You can also get slack notifications.
          </AccordionItem>
          <AccordionItem title="Why do you send texts as opposed to emails?">
            You know that items go quick. Texts are delivered much quicker than
            email, so you will be notified before anyone else when an item is
            back in stock.
          </AccordionItem>
          <AccordionItem
            title={`What if I can't find a link because lululemon doesn't have the item on their site?`}
          >
            Go to <Link to="/database">https://lemondrops.io/database</Link> and
            you can search for a previously watched item, and you can copy the
            url.
          </AccordionItem>
          <AccordionItem
            title={`Why have I stopped recieving texts of an item I'm watching?`}
          >
            After an item is back in stock, we will send you a text message,
            then we stop watching that item (else, you would get a text every 3
            minutes until it goes out of stock). So if you weren't able to
            purchase the item, you can re-watch the item. You can get a link
            from the lululemon website/app, or in your lemondrops profile page,
            under "PAST".
          </AccordionItem>
          <AccordionItem title="When do items typically restock?">
            Typically early in the morning, on Tuesdays and Thursdays, but
            that's not always the case, that's why we created this service!
          </AccordionItem>
          <AccordionItem title="How do I cancel?">
            When you go to the <a href="/profile">profile</a> page, click "Canel
            Subscription" and you can cancel.
          </AccordionItem>
        </Accordion>
      </div>
    </GenericSection>
  );
}
export default FAQ;
