import React from "react";
import HeroSplit from "../components/sections/HeroSplit";
// import Clients from "../components/sections/Clients";
// import FeaturesSplit from "../components/sections/FeaturesSplit";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import Cta from "../components/sections/Cta";
import Pricing from "../components/sections/Pricing";
import Testimonial from "../components/sections/Testimonial";
import RecentRestocks from "../components/sections/RecentRestocks";
import FAQ from "../components/sections/FAQ";
// import FeaturesSplit from "../components/sections/FeaturesSplit";
import Team from "../components/sections/Team";
import Button from "../components/elements/Button";
import FeaturesSplit from "../components/sections/FeaturesSplit";
// import Cta from "../components/sections/Cta";
// import HeroFull from "../components/sections/HeroFull";

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <HeroFull invertMobile imageFill className="illustration-section-01" /> */}
        {/* <HeroSplit imageFill className="illustration-section-01" /> */}
        <HeroSplit imageFill className="illustration-section-02" />
        <RecentRestocks topDivider />
        <FeaturesSplit />
        <FeaturesTiles topDivider className="illustration-section-03" />
        <Testimonial hasBgColor className="illustration-section-05" />
        <FAQ />
        <Pricing
          // pricingSwitcher
          className="illustration-section-07"
        />
        <Team />
        <Cta
          invertColor
          hasBgColor
          topDivider
          bottomDivider
          split
          className="reveal-from-top"
        />
        <section
          style={{
            backgroundColor: "rgb(239 68 68)",
            color: "white",
          }}
        >
          <div
            className="container"
            style={{
              fontFamily: "IBM Plex Sans, sans-serif",
              textAlign: "center",
              padding: "3rem 0",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
              }}
            >
              <span
                style={{
                  display: "block",
                  height: "40px",
                  width: "40px",
                  fill: "white",
                }}
              >
                <svg
                  style={{
                    display: "block",
                    height: "100%",
                    width: "100%",
                  }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  focusable="false"
                  role="presentation"
                >
                  <path
                    d="M12 1c6.074 0 10.999 4.925 10.999 11 0 6.074-4.925 10.999-11 10.999-6.074 0-10.999-4.925-10.999-11C1 5.925 5.925 1 12 1zm0 18.173a7.174 7.174 0 10-.001-14.347 7.174 7.174 0 000 14.347zm0-3.653a3.52 3.52 0 110-7.04 3.52 3.52 0 010 7.04z"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </span>
            </div>

            <span
              style={{
                display: "block",
              }}
            >
              Looking for Target Restock Alerts?
            </span>
            <Button
              style={{
                marginTop: "1rem",
                backgroundColor: "white",
                color: "rgb(239 68 68)",
              }}
              tag="a"
              target="_blank"
              color="primary"
              wideMobile
              href="https://www.arrowrestocks.com/"
            >
              Sign Up
            </Button>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Home;
