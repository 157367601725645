import React from "react";
import { Redirect, Route } from "react-router-dom";

const ProtectedRoute = ({
  component: Component,
  layout: Layout,
  isSignedIn,
  isLoading,
  user,
  ...rest
}) => {
  Layout =
    Layout === undefined
      ? (props) => <React.Fragment>{props.children}</React.Fragment>
      : Layout;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading) {
          return (
            <Layout>
              <section className={"hero section"}>
                <div className="container">
                  <p>Loading...</p>
                </div>
              </section>
            </Layout>
          );
        }
        if (isSignedIn) {
          return (
            <Layout>
              <Component {...props} isSignedIn={isSignedIn} user={user} />
            </Layout>
          );
        }
        return <Redirect to="/login" />;
      }}
    />
  );
};

export default ProtectedRoute;
