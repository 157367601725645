import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import Button from "../elements/Button";

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
};

class Cta extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "cta section center-content-mobile",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "cta-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider",
      split && "cta-split"
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <div className="cta-slogan">
              <h3 className="m-0">Get the products you love NOW!</h3>
            </div>
            <div className="cta-action">
              <Button tag="a" color="primary" wideMobile href="/signup">
                Sign Up
              </Button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;

// import React from "react";
// import PropTypes from "prop-types";
// import classNames from "classnames";
// import { SectionProps } from "../../utils/SectionProps";
// // import Button from "../elements/Button";

// const propTypes = {
//   ...SectionProps.types,
//   split: PropTypes.bool,
// };

// const defaultProps = {
//   ...SectionProps.defaults,
//   split: false,
// };

// class Cta extends React.Component {
//   render() {
//     const {
//       className,
//       topOuterDivider,
//       bottomOuterDivider,
//       topDivider,
//       bottomDivider,
//       hasBgColor,
//       invertColor,
//       split,
//       ...props
//     } = this.props;

//     const outerClasses = classNames(
//       "cta section center-content",
//       topOuterDivider && "has-top-divider",
//       bottomOuterDivider && "has-bottom-divider",
//       hasBgColor && "has-bg-color",
//       invertColor && "invert-color",
//       className
//     );

//     const innerClasses = classNames(
//       "cta-inner section-inner reveal-scale-down",
//       topDivider && "has-top-divider",
//       bottomDivider && "has-bottom-divider",
//       split && "cta-split"
//     );

//     return (
//       <section {...props} className={outerClasses}>
//         <div className="container">
//           <div className={innerClasses}>
//             <div className="cta-slogan">
//               <div className="container-xs">
//                 <h2 className="mt-0 mb-8">Lifetime access for $47</h2>
//                 <p className="m-0">
//                   Only 20 spots! Get lifetime access, which will include current
//                   features, as well as any new ones we build. When we launch the
//                   price will be $100/annually. Lock in to get this service
//                   forever at this price!
//                 </p>
//               </div>
//             </div>
//             <div className="cta-action">
//               <a
//                 class="gumroad-button"
//                 href="https://gum.co/kDtQu?wanted=true"
//                 target="_blank"
//                 rel="noopener noreferrer"
//                 data-gumroad-single-product="true"
//               >
//                 Get Lifetime Access
//               </a>
//               {/* <Button tag="a" color="secondary" wideMobile href="#0">
//                 Get lifetime access
//               </Button> */}
//             </div>
//           </div>
//         </div>
//       </section>
//     );
//   }
// }

// Cta.propTypes = propTypes;
// Cta.defaultProps = defaultProps;

// export default Cta;
