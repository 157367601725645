import React from "react";
import classNames from "classnames";

const ThankYou = () => {
  const outerClasses = classNames("hero section");
  return (
    <section className={outerClasses}>
      <div className="container">
        <h1>Thank you so much for signing up!</h1>
        <p>
          Go to the <a href="/profile">profile</a> page to watch items!
        </p>
      </div>
    </section>
  );
};

export default ThankYou;
