import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import classNames from "classnames";
import Button from "../components/elements/Button";
import ButtonGroup from "../components/elements/ButtonGroup";
import Modal from "react-modal";
import { useCallback } from "react";
import LuluLinkInput from "../components/elements/LuluLinkInput";
// import toast from "react-hot-toast";
import { Link } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
// const onBoardingModalCustomStyles = {
//   content: {
//     top: "25%",
//     left: "50%",
//     right: "auto",
//     bottom: "auto",
//     marginRight: "-50%",
//     transform: "translate(-50%, -50%)",
//   },
// };
Modal.setAppElement("#root");

export function Profile({ isSignedIn, user }) {
  const [lemonDrops, setLemonDrops] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState("");
  const [customerPortalUrl, setCustomerPortalUrl] = useState("");
  const [selectedLemonDrop, setSelectedLemonDrop] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  // const [isOnboadingModalOpen, setIsOnboardingModalOpen] = useState(false);

  const userId = user?.uid;
  const outerClasses = classNames("hero section");

  const fetchUserLemonDrops = useCallback(async () => {
    try {
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API}/getUserLemonDrops`,
        data: {
          type: activeTab === 0 ? "current" : "past",
          userId,
        },
      });
      setLemonDrops(res?.data?.lemonDrops);
    } catch (error) {
      console.log("error", error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [userId, activeTab]);

  useEffect(() => {
    async function run() {
      if (shouldRefresh) {
        await fetchUserLemonDrops();
        setShouldRefresh(false);
      }
    }
    run();
  }, [shouldRefresh, fetchUserLemonDrops]);

  useEffect(() => {
    async function fetchCustomerPortalUrl() {
      try {
        const res = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API}/customer-portal/${userId}`,
        });
        setCustomerPortalUrl(res?.data?.url);
      } catch (error) {
        console.log("error?.message", error?.message);
        setError(error?.message);
      }
    }
    if (userId) {
      fetchUserLemonDrops();
      fetchCustomerPortalUrl();
      // isOnboardingComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn, userId, fetchUserLemonDrops, activeTab]);

  function goToCustomerPortal() {
    window.open(customerPortalUrl);
  }

  function openModal(lemonDrop) {
    setIsModalOpen(true);
    setSelectedLemonDrop(lemonDrop);
  }

  // async function rewatch(lemonDrop) {
  //   setLemonDropRewatching(lemonDrop);
  //   try {
  //     await axios({
  //       method: "POST",
  //       url: `${process.env.REACT_APP_API}/rewatch`,
  //       data: {
  //         lemonDropId: lemonDrop.lemonDropId,
  //       },
  //     });
  //     setShouldRefresh(true);
  //   } catch (error) {
  //     console.log("error", error.message);
  //     setError(error.message);
  //   } finally {
  //     setLemonDropRewatching({});
  //   }
  // }

  async function deleteLemonDrop() {
    try {
      setIsDeleting(true);
      await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_API}/lemondrops`,
        data: {
          id: selectedLemonDrop?.lemonDropId,
        },
      });
    } catch (error) {
      setError(error.message);
    } finally {
      await fetchUserLemonDrops();
      setIsDeleting(false);
      closeModal();
    }
  }

  function closeModal() {
    setIsModalOpen(false);
    setSelectedLemonDrop({});
  }

  const selectedStyle = {
    borderBottom: "2px solid #00d1b2",
  };
  const unselectedStyle = {
    borderBottom: "2px solid #e5e5e5",
  };

  // async function isOnboardingComplete() {
  //   try {
  //     const res = await axios({
  //       method: "POST",
  //       url: `${process.env.REACT_APP_API}/isOnBoardingComplete`,
  //       data: {
  //         userId,
  //       },
  //     });
  //     setIsOnboardingModalOpen(!res?.data);
  //   } catch (error) {
  //     console.log("error", error.message);
  //     toast.error(error.message);
  //   }
  // }

  // async function completeOnboarding() {
  //   try {
  //     await axios({
  //       method: "POST",
  //       url: `${process.env.REACT_APP_API}/completeOnboarding`,
  //       data: {
  //         userId,
  //       },
  //     });
  //     setIsOnboardingModalOpen(false);
  //   } catch (error) {
  //     console.log("error", error.message);
  //     toast.error(error.message);
  //   }
  // }

  return (
    <>
      {/* <Modal
        style={onBoardingModalCustomStyles}
        isOpen={isOnboadingModalOpen}
        onRequestClose={completeOnboarding}
        contentLabel="Modal"
      >
        <p style={{ fontSize: "1.5rem" }}>
          Join the slack group in order to receive notifications!
        </p>
        <p>
          <a
            target={"_blank"}
            href="https://join.slack.com/t/lemondropsio/shared_invite/zt-1ehc9rig2-eIUlrkup87mde6tGQwUU0Q"
          >
            Slack Group
          </a>
        </p>
        <Button color="primary" onClick={completeOnboarding}>
          Sweet! I Joined!
        </Button>
      </Modal> */}
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
      >
        <p>
          Are you sure you want to stop watching the{" "}
          <b>{selectedLemonDrop?.itemName}</b>?
        </p>

        <ButtonGroup>
          <Button
            loading={isDeleting}
            color="primary"
            onClick={deleteLemonDrop}
          >
            Yes, Delete
          </Button>
          <Button color="dark" onClick={closeModal}>
            Close
          </Button>
        </ButtonGroup>
      </Modal>
      <section className={outerClasses}>
        <div className="container">
          <div style={{ marginTop: 30 }}>
            <Button
              size="sm"
              tag="a"
              style={{ marginBottom: 10 }}
              className="button button-primary"
              href="/select-plan"
            >
              Select Subscription Plan
            </Button>
            {customerPortalUrl && (
              <Button
                size="sm"
                style={{ display: "block", marginBottom: 10 }}
                className="button button-dark"
                onClick={goToCustomerPortal}
              >
                Manage Subscription
              </Button>
            )}
          </div>
        </div>
        <div className="container">
          <h1 style={{ marginBottom: "0" }}>Watch a product</h1>
          <p style={{ fontSize: ".75rem" }}>
            *Use the link from the lululemon app. That has the best results.
          </p>
          <LuluLinkInput
            fetchUserLemonDrops={fetchUserLemonDrops}
            user={user}
          />
          <hr />
          <ul style={{ fontSize: "0.75rem" }}>
            <li>
              <b>Need help?</b> Send me a message in the chat in the lower right
              of the screen
            </li>
            <li>
              <b>Need a link to an item not on the lululemon site?</b> Go to{" "}
              <Link to="/database">lemondrops.io/database</Link>
            </li>
            <li>
              <b>Slack link </b>
              <a
                target={"_blank"}
                href="https://join.slack.com/t/lemondropsio/shared_invite/zt-1ehc9rig2-eIUlrkup87mde6tGQwUU0Q"
              >
                Click me
              </a>
            </li>
            <li>
              <b>Why are items disappearing from my CURRENT list?</b> After an
              item is back in stock, we will send you a text message, then we
              stop watching that item (else, you would get a text every 3
              minutes until it goes out of stock). So if you weren't able to
              purchase the item, you can re-watch the item. You can get a link
              from the lululemon website/app, or in the PAST tab.
            </li>
          </ul>
          {isLoading && "Loading..."}
          {error && <p style={{ color: "red" }}>An error ocurred: {error}</p>}
          {lemonDrops?.length === 0
            ? "Shoot looks like you're not watching any items. After selecting a subscription plan, submit a link and start watching a product!"
            : ""}

          <div style={{ marginBottom: "1rem" }}>
            <Button
              onClick={() => setActiveTab(0)}
              style={activeTab === 0 ? selectedStyle : unselectedStyle}
            >
              Current
            </Button>
            <Button
              onClick={() => setActiveTab(1)}
              style={activeTab === 1 ? selectedStyle : unselectedStyle}
            >
              Past
            </Button>
          </div>
          <Table>
            <Thead>
              <Tr>
                <Th>Product Name</Th>
                <Th>Color</Th>
                <Th>Size</Th>
                <Th>Price</Th>
                <Th>Watch Type</Th>
                <Th>{activeTab === 0 ? "Created" : "Text sent"}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {lemonDrops?.map((lemonDrop) => {
                return (
                  <Tr key={lemonDrop?.lemonDropId}>
                    <Td>
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={lemonDrop.url}
                      >
                        {lemonDrop?.itemName}
                      </a>
                    </Td>
                    <Td>{lemonDrop?.color}</Td>
                    <Td>{lemonDrop?.size}</Td>
                    <Td>${lemonDrop?.price}</Td>
                    <Td>
                      {lemonDrop?.watchType === "OOS"
                        ? "Restock"
                        : "Price drop"}
                    </Td>
                    <Td>
                      {activeTab === 0
                        ? new Date(lemonDrop?.createdAt).toLocaleDateString(
                            "en-US"
                          )
                        : new Date(lemonDrop?.updatedAt).toLocaleDateString(
                            "en-US"
                          )}
                    </Td>
                    <Td>
                      {activeTab === 0 && (
                        <Button
                          color="dark"
                          onClick={() => openModal(lemonDrop)}
                        >
                          Delete
                        </Button>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
      </section>
    </>
  );
}
