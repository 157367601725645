import axios from "axios";
import React from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Button from "../components/elements/Button";
import Select from "../components/elements/Select";
import { formatUrlWithAffiliateCode } from "../utils/utils";

export default function ProductPage() {
  const productId = window.location.pathname.split("/")[2];
  const [product, setProduct] = React.useState({});
  const [timezone, setTimezone] = React.useState("America/New_York");
  const [sizeSelected, setSizeSelected] = React.useState("");
  const [colorSelected, setColorSelected] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);
  const [restockHistory, setRestockHistory] = React.useState([]);

  useEffect(() => {
    if (productId) {
      getProduct();
    }
    async function getProduct() {
      try {
        const res = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API}/getProduct`,
          data: {
            productId,
          },
        });
        setIsLoading(false);
        setProduct(res.data?.product?.data?.attributes);
        setRestockHistory(res.data?.lemonDrops);
      } catch (error) {
        toast.error(error.message);
      }
    }
  }, [productId]);

  function displayProductEducation() {
    return product?.productEducation?.map((item) => {
      return (
        <div>
          <h1>{item?.title}</h1>
          {item?.attributes?.map((attribute) => {
            return <p>{attribute?.text}</p>;
          })}
        </div>
      );
    });
  }

  function createSizeOptions() {
    const sizes = new Set(restockHistory?.map((item) => item?.size));
    sizes.add("");
    return Array.from(sizes).map((size) => {
      return <option value={size}>{size}</option>;
    });
  }

  function createColorOptions() {
    const colors = new Set(restockHistory?.map((item) => item?.color));
    colors.add("");
    return Array.from(colors).map((color) => {
      return <option value={color}>{color}</option>;
    });
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="wrapper container section">
      <div className="col-md-6 col-lg-5 pdp-summary-offset">
        <h1>
          When does {product?.["product-summary"]?.["product-name"]} restock?
        </h1>
      </div>
      <div className="row between-md pdp-components-wrapper animated fadeIn">
        <div
          style={{
            width: "100%",
            height: "100%",
            maxHeight: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              maxHeight: "30rem",
            }}
            src={`${product?.["product-carousel"]?.[0]?.["image-info"]?.[0]}?wid=1080&op_usm=0.5,2,10,0&fmt=webp&qlt=80,1&fit=constrain,0&op_sharpen=0&resMode=sharp2&iccEmbed=0&printRes=72`}
            alt="product"
          />
        </div>
      </div>

      <div
        style={{
          marginBottom: "2rem",
          marginTop: "2rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button tag={"a"} href="/profile" color={"primary"}>
          Notify me when it's back in stock!
        </Button>
      </div>

      {/* selectors */}
      <div>
        {/* timezone */}
        <Select
          onChange={(e) => setTimezone(e.target.value)}
          value={timezone}
          label="Timezone"
        >
          <option value="America/New_York">Eastern</option>
          <option value="America/Chicago">Central</option>
          <option value="America/Denver">Mountain</option>
          <option value="America/Los_Angeles">Pacific</option>
        </Select>

        {/* size */}
        <Select
          onChange={(e) => setSizeSelected(e.target.value)}
          value={sizeSelected}
          label="Size"
        >
          {createSizeOptions()}
        </Select>

        {/* color */}
        <Select
          onChange={(e) => setColorSelected(e.target.value)}
          value={colorSelected}
          label="Color"
        >
          {createColorOptions()}
        </Select>
      </div>

      <div>
        <h2>Restock History</h2>
        {restockHistory?.length === 0 ? (
          <div>No restock history. Watch it now to start the history!</div>
        ) : (
          <Table>
            <Thead>
              <Tr>
                <Th>Product Name</Th>
                <Th>Color</Th>
                <Th>Size</Th>
                <Th>Date</Th>
                <Th>Time</Th>
              </Tr>
            </Thead>
            <Tbody>
              {restockHistory
                ?.filter((lemonDrop) => {
                  if (sizeSelected && colorSelected) {
                    return (
                      lemonDrop?.size === sizeSelected &&
                      lemonDrop?.color === colorSelected
                    );
                  }
                  if (sizeSelected) {
                    return lemonDrop?.size === sizeSelected;
                  }
                  if (colorSelected) {
                    return lemonDrop?.color === colorSelected;
                  }
                  return true;
                })
                ?.map((lemonDrop) => {
                  const time = Intl.DateTimeFormat("en-US", {
                    hour: "numeric",
                    minute: "2-digit",
                    timeZone: timezone,
                  }).format(new Date(lemonDrop?.updatedAt));
                  return (
                    <Tr key={lemonDrop?.lemonDropId}>
                      <Td>
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={formatUrlWithAffiliateCode(lemonDrop.url)}
                        >
                          {lemonDrop?.itemName}
                        </a>
                      </Td>
                      <Td>{lemonDrop?.color}</Td>
                      <Td>{lemonDrop?.size}</Td>
                      <Td>
                        {new Date(lemonDrop?.updatedAt).toLocaleDateString(
                          "en-US"
                        )}
                      </Td>
                      <Td>{time}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        )}
      </div>

      <div>
        <h4>Why lululemon Made it</h4>
        <p>{product?.whyWeMadeThis?.text}</p>
      </div>

      <div>
        <h4>Designed For</h4>
        <p>{product?.designedFor?.activityText}</p>
      </div>

      {displayProductEducation()}
    </div>
  );
}
