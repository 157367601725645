import React from "react";
import classNames from "classnames";

const SMS = () => {
  const outerClasses = classNames("hero section");
  return (
    <section className={outerClasses}>
      <div className="container">
        <h1>SMS Opt Out Policy</h1>
        <p>Last updated: March 27, 2021</p>
        <p>
          Anytime you'd like to opt out of SMS message notifications, respond to
          the number we send you notifications with the word "STOP".
        </p>
      </div>
    </section>
  );
};

export default SMS;
