import React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <a href="/blog">Blog</a>
        </li>
        <li>
          <Link to="/refund-policy">Refund Policy</Link>
        </li>
        {/* <li>
          <Link to="/about-us">About Us</Link>
        </li> */}
        <li>
          <Link to="/faqs">FAQ's</Link>
        </li>
        <li>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
        <li>
          <Link to="/sms/">SMS Opt Out Policy</Link>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
