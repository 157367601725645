import axios from "axios";
import React from "react";
import { useEffect } from "react";

export default function PSeoFooter() {
  const [parents, setParents] = React.useState({});

  useEffect(() => {
    // fetch all the parents and their categories
    getParents();
    async function getParents() {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API}/getParents`);
        setParents(res.data);
      } catch (error) {
        console.log("error at getParents", error.message);
      }
    }
  }, []);

  function renderParents() {
    if (!Object.keys(parents)?.length) return null;
    return Object.keys(parents).map((parent) => {
      return (
        <div className="footer-block">
          <h6 className="footer-block-title">
            {parents?.[parent]?.parent_name}
          </h6>
          <ul className="list-reset">
            {parents?.[parent]?.categories.map((category) => {
              return (
                <li className="mb-3">
                  <a className="" href={`/category/${category?.id}`}>
                    {category?.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      );
    });
  }

  return <div className="footer-blocks">{renderParents()}</div>;
}
