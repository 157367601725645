import classNames from "classnames";
import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import Pricing from "../components/sections/Pricing";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export const SelectPlan = (props) => {
  const outerClasses = classNames("hero section");
  return (
    <section className={outerClasses}>
      <div className="container">
        <Pricing {...props} stripe={stripePromise} onPricingPage />
      </div>
    </section>
  );
};
