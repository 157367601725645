import React, { useEffect, useState } from "react";
import axios from "axios";
import classNames from "classnames";
import SectionHeader from "./partials/SectionHeader";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { formatUrlWithAffiliateCode } from "../../utils/utils";

const RecentRestocks = (props) => {
  const [lemonDrops, setLemonDrops] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getRecentRestocks();
  }, []);

  async function getRecentRestocks() {
    try {
      setIsLoading(true);
      const res = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API}/recentRestocks`,
      });
      setLemonDrops(res?.data?.lemonDrops);
      setIsLoading(false);
    } catch (error) {
      console.log("error ", error);
    }
  }

  const {
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
  } = props;

  const outerClasses = classNames(
    // "features-tiles section center-content",
    "features-tiles section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "features-tiles-inner section-inner",
    // "reveal-from-top",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Recent Restocks",
    paragraph:
      "These are only for the US. (We also provide alerts for Canada 🇨🇦)",
  };

  if (isLoading) {
    return "Loading...";
  }

  return (
    <section className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            data={sectionHeader}
            className="center-content"
            width={72}
            height={72}
          />
          <Table>
            <Thead>
              <Tr>
                <Th>Product Name</Th>
                <Th>Color</Th>
                <Th>Size</Th>
                <Th>Date</Th>
                <Th>Time (US/EST)</Th>
              </Tr>
            </Thead>
            <Tbody>
              {lemonDrops?.map((lemonDrop) => {
                const time = Intl.DateTimeFormat("en-US", {
                  hour: "numeric",
                  minute: "2-digit",
                  timeZone: "America/New_York",
                }).format(new Date(lemonDrop?.updatedAt));
                return (
                  <Tr key={lemonDrop?.lemonDropId}>
                    <Td>
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={formatUrlWithAffiliateCode(lemonDrop.url)}
                      >
                        {lemonDrop?.itemName}
                      </a>
                    </Td>
                    <Td>{lemonDrop?.color}</Td>
                    <Td>{lemonDrop?.size}</Td>
                    <Td>
                      {new Date(lemonDrop?.updatedAt).toLocaleDateString(
                        "en-US"
                      )}
                    </Td>
                    <Td>{time}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </div>
      </div>
    </section>
  );
};

RecentRestocks.propTypes = {};

RecentRestocks.propTypes = {};

export default RecentRestocks;
