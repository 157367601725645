import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase";
import axios from "axios";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: "/thanks",
  // We will display Google and Facebook as auth providers.
  signInOptions: [firebase.auth.PhoneAuthProvider.PROVIDER_ID],
  callbacks: {
    signInSuccessWithAuthResult: async (authObj) => {
      // create user
      const user = authObj?.user;
      console.log("user", user);
      try {
        const phone = authObj?.user?.phoneNumber?.slice(1);
        await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API}/createUser`,
          data: {
            userId: user?.uid,
            phone,
          },
        });
        // TODO: need to ask for country code now
        window.location.href = "/select-country";
        // window.location.href = "/select-plan";
        return true;
      } catch (error) {
        // if there's an error, its probably cause they're already in the DB (cross your fingers), so just send them to profile
        window.location.href = "/profile";
        console.log("error creating user in render", error.message);
      }
    },
  },
};

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const SignupForm = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {
  const outerClasses = classNames(
    "signin section",
    topOuterDivider && "has-top-divider",
    bottomOuterDivider && "has-bottom-divider",
    hasBgColor && "has-bg-color",
    invertColor && "invert-color",
    className
  );

  const innerClasses = classNames(
    "signin-inner section-inner",
    topDivider && "has-top-divider",
    bottomDivider && "has-bottom-divider"
  );

  const sectionHeader = {
    title: "Get the lululemon you've been waiting for",
  };

  return (
    <section {...props} className={outerClasses}>
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader
            tag="h1"
            data={sectionHeader}
            className="center-content"
          />
          <div className="tiles-wrap">
            <div className="tiles-item">
              <div className="tiles-item-inner">
                <form>
                  <fieldset>
                    <div className="mb-12">
                      <StyledFirebaseAuth
                        uiConfig={uiConfig}
                        firebaseAuth={firebase.auth()}
                      />
                    </div>
                    <div className="mt-24 mb-32">
                      {/* <Button color="primary" wide>
                        Sign up
                      </Button> */}
                    </div>
                  </fieldset>
                </form>
                {/* <div className="signin-bottom has-top-divider">
                  <div className="pt-32 text-xs center-content text-color-low">
                    Already have an account?{" "}
                    <Link to="/login/" className="func-link">
                      Login
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

SignupForm.propTypes = propTypes;
SignupForm.defaultProps = defaultProps;

export default SignupForm;
