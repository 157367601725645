import axios from "axios";
import React from "react";
import { useEffect } from "react";

export default function CategoryPage() {
  const [products, setProducts] = React.useState([]);
  const categoryId = window.location.pathname.split("/")[2];

  useEffect(() => {
    getProductsByCategoryId();
    async function getProductsByCategoryId() {
      try {
        const res = await axios({
          method: "POST",
          url: `${process.env.REACT_APP_API}/getProductsByCategoryId`,
          data: {
            categoryId,
          },
        });
        setProducts(res.data);
      } catch (error) {
        console.log("error at getProductsByCategoryId", error.message);
      }
    }
  }, [categoryId]);

  return (
    <div className="wrapper container section">
      <h1>Category Page</h1>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridGap: "1rem",
        }}
      >
        {products?.map((product) => {
          const productName =
            product?.json?.data?.attributes?.["product-summary"]?.[
              "display-name"
            ];
          // lowercase and replace spaces with dashes
          const productSlug = productName?.toLowerCase().replace(/ /g, "-");
          return (
            <div
              key={product?.product_id}
              style={{
                padding: "1rem",
              }}
            >
              <a href={`/product/${product?.product_id}/${productSlug}`}>
                <div>
                  <img
                    style={{
                      maxHeight: "30rem",
                    }}
                    src={`${product?.json?.data?.attributes?.["product-carousel"]?.[0]?.["image-info"]?.[0]}`}
                    alt="product"
                  />
                  <span>{productName}</span>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
