import React from "react";
import classNames from "classnames";
import { SectionSplitProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

class FeaturesSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-split section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-split-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const splitClasses = classNames(
      "split-wrap",
      invertMobile && "invert-mobile",
      invertDesktop && "invert-desktop",
      alignTop && "align-top"
    );

    const sectionHeader = {
      title: "Features",
      // paragraph: 'Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.'
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content reveal-from-top"
            ></SectionHeader>
            <div className={splitClasses}>
              <div className="split-item reveal-from-top">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">
                    Fastest Restock alerts on the planet
                  </h3>
                  <p className="m-0">
                    Compare us to ANY other restock alert service. We are the
                    fastest and most reliable. Even lululemon's is not as good.
                    We check every 2 minutes and send you a text, which is the
                    fastest way to get the alert to you instead of email. Try
                    and see for yourself.
                  </p>
                </div>
                <div>
                  <Image
                    src={require("./../../assets/images/shopping.svg")}
                    alt="Features split 02"
                    width={528}
                    height={396}
                  />
                </div>
              </div>

              <div className="split-item reveal-from-top">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">Price Drop Alerts</h3>
                  <p className="m-0">
                    Save money by knowing when your favorite products are on
                    sale!
                  </p>
                </div>
                <Image
                  src={require("./../../assets/images/discount.svg")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>

              <div className={splitClasses}>
                <div className="split-item reveal-from-top">
                  <div className="split-item-content center-content-mobile">
                    <h3 className="mt-0 mb-16">
                      Watch when any color is in stock
                    </h3>
                    <p className="m-0">
                      In love with an item and don't care which color is back in
                      stock? Sweet. Send us a link and we'll text you as soon as
                      any color is in stock.
                    </p>
                  </div>
                  <div>
                    <Image
                      src={require("./../../assets/images/shopping.svg")}
                      alt="Features split 02"
                      width={528}
                      height={396}
                    />
                  </div>
                </div>
              </div>

              <div className="split-item reveal-from-top">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16">
                    Can't find a link on lululemon's site?
                  </h3>
                  <p className="m-0">
                    We gotchu covered. Check out our entire{" "}
                    <a href="/database">database</a> to search for links, and
                    get a text when it's back in stock.
                  </p>
                </div>
                <Image
                  src={require("./../../assets/images/discount.svg")}
                  alt="Features split 01"
                  width={528}
                  height={396}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

// const imgOddStyle = {
//   position: "absolute",
//   width: "118.18%",
//   maxWidth: "118.18%",
//   top: "-6.31%",
//   left: "-16.48%",
// };

// const imgEvenStyle = {
//   position: "absolute",
//   width: "118.18%",
//   maxWidth: "118.18%",
//   top: "-6.31%",
//   left: "-1.51%",
// };

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
