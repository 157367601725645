import React from "react";
import classNames from "classnames";

const AboutUs = () => {
  const outerClasses = classNames("hero section");
  return (
    <section className={outerClasses}>
      <div className="container">
        <h1>About Us</h1>
        <p>Hey there! Thanks for checking us out.</p>
        <p>
          I'm a software engineer from Northeast Ohio, currently residing in
          Austin, Texas.
        </p>
        <p>
          The inspiration for lululemonDrops came from my wife. She was wanting
          items that were constantly out of stock and I was frustrated that
          there were no restock notifications, so I built it myself!{" "}
          <span role="img" aria-label="smile">
            😆
          </span>
        </p>
        <p>Email us if you have any questions: getlululemondrops@gmail.com</p>
      </div>
    </section>
  );
};

AboutUs.propTypes = {};

AboutUs.propTypes = {};

export default AboutUs;
