import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Testimonial extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "testimonial section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      // "testimonial-inner section-inner pt-0",
      "testimonial-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames(
      "tiles-wrap illustration-section-08",
      pushLeft && "push-left"
    );

    const sectionHeader = {
      title: "Testimonials",
      paragraph: "Thousands of successful purchases",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content invert-color"
            />
            <div className={tilesClasses}>
              <div className="tiles-item reveal-from-top">
                <Image
                  src={require("./../../assets/images/testimonial_9.jpg")}
                  alt="Testimonial 09"
                  // height={56}
                />
              </div>
              <div className="tiles-item reveal-from-top">
                <Image
                  src={require("./../../assets/images/testimonial3.png")}
                  alt="Testimonial 03"
                  // height={56}
                />
              </div>
              <div className="tiles-item reveal-from-top iframe-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/F7Cw63fUjUY"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="tiles-item reveal-from-top">
                <Image
                  src={require("./../../assets/images/testimonial1.png")}
                  alt="Testimonial 01"
                  // height={56}
                />
              </div>
              <div className="tiles-item reveal-from-top">
                <Image
                  src={require("./../../assets/images/testimonial2.png")}
                  alt="Testimonial 02"
                  // height={56}
                />
              </div>
              <div className="tiles-item reveal-from-top">
                <Image
                  src={require("./../../assets/images/testimonial4.png")}
                  alt="Testimonial 04"
                  // height={56}
                />
              </div>
              <div className="tiles-item reveal-from-top">
                <Image
                  src={require("./../../assets/images/testimonial5.png")}
                  alt="Testimonial 05"
                  // height={56}
                />
              </div>
              <div className="tiles-item reveal-from-top">
                <Image
                  src={require("./../../assets/images/testimonial6.png")}
                  alt="Testimonial 06"
                  // height={56}
                />
              </div>
              <div className="tiles-item reveal-from-top">
                <Image
                  src={require("./../../assets/images/testimonial7.jpeg")}
                  alt="Testimonial 07"
                  // height={56}
                />
              </div>
              <div className="tiles-item reveal-from-top">
                <Image
                  src={require("./../../assets/images/testimonial8.png")}
                  alt="Testimonial 08"
                  // height={56}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;
