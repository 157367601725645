import React, { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Button from "./Button";
import Input from "./Input";
import toast from "react-hot-toast";
import Switch from "./Switch";

const LuluLinkInput = (props) => {
  const [luluLink, setLuluLink] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [lemonDrop, setLemonDrop] = useState({});
  const [isPriceDrop, setIsPriceDrop] = useState(false);
  const [isOOS, setIsOOS] = useState(true);
  const [isAnyColor, setIsAnyColor] = useState(false);

  function validate() {
    if (!luluLink?.includes("shop.lululemon")) {
      return "Hm looks like this link is not from lululemon. Make sure it looks something like https://shop.lululemon.com/p/women-pants/Align-Pant-Full-Length-28-MD/_/prod8840324?color=39793&sz=6";
    }

    const params = `?${luluLink?.split("?")[1]}`;
    const urlParams = new URLSearchParams(params);
    const size = urlParams.get("sz");
    const color = urlParams.get("color");

    if (!size) {
      return "No size selected. Double check you have selected a size. The app works better than using the website.";
    } else if (!color) {
      return "No color selected. Double check you have selected a color. The app works better than using the website.";
    } else if (!luluLink?.includes("prod")) {
      return "No productId on the url. Try using the app. Text 330-354-1427 if you need additional help.";
    }
  }

  async function createLemonDrop() {
    try {
      setIsLoading(true);
      const res = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API}/createLemonDropFromWebsite`,
        data: {
          url: luluLink,
          phone: props?.user?.phoneNumber,
          watchType: isOOS ? "OOS" : "PRICE_DROP",
          isAnyColor,
        },
      });
      if (props.fetchUserLemonDrops) {
        props.fetchUserLemonDrops();
      }
      if (res?.data?.message) {
        toast(res?.data?.message);
      } else {
        setLemonDrop(res?.data?.lemonDrop);
      }
    } catch (error) {
      const msg = error.response?.data?.statusText;
      console.log("error", msg);
      toast.error(msg);
    } finally {
      setIsLoading(false);
    }
  }

  function onSubmitLink(e) {
    e.preventDefault();
    setError("");
    const validationError = validate();
    if (validationError) {
      setError(validationError);
    } else {
      createLemonDrop();
    }
  }

  return (
    <form onSubmit={onSubmitLink}>
      {error && (
        <p
          style={{
            color: "red",
            textAlign: "left",
            lineHeight: "20px",
            fontSize: 14,
          }}
        >
          {error}
        </p>
      )}
      {lemonDrop?.sku ? (
        <div>
          <p>
            Sweet! We'll notify you as soon as{" "}
            {lemonDrop?.watchType === "OOS"
              ? `it's back in stock!`
              : `the price drops!`}
          </p>
          <Table>
            <Thead>
              <Tr>
                <Th>Product Name</Th>
                <Th>Color</Th>
                <Th>Size</Th>
                <Th>Price</Th>
                <Th>Watch Type</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={lemonDrop.url}
                  >
                    {lemonDrop?.itemName}
                  </a>
                </Td>
                <Td>{lemonDrop?.color}</Td>
                <Td>{lemonDrop?.size}</Td>
                <Td>${lemonDrop?.price}</Td>
                <Td>
                  {lemonDrop?.watchType === "OOS" ? "Restock" : "Price drop"}
                </Td>
              </Tr>
            </Tbody>
          </Table>
          <Button
            onClick={() => {
              setLemonDrop({});
              setLuluLink("");
              setIsAnyColor(false);
            }}
            color="secondary"
          >
            Watch another item
          </Button>
        </div>
      ) : (
        <>
          <Input
            value={luluLink}
            onChange={(e) => setLuluLink(e.target.value)}
            type="text"
            formGroup="mobile"
            // label="Enter link to lululemon product"
            labelHidden
            placeholder="https://shop.lululemon.com/p/women-pants/Align-Pant-Full-Length-28-MD/_/prod8840324?color=39793&sz=6"
          >
            <Button loading={isLoading} type="submit" color="primary">
              Submit
            </Button>
          </Input>
          <Switch
            checked={isOOS}
            onChange={() => {
              if (isOOS) {
                setIsAnyColor(false);
              }
              setIsOOS(!isOOS);
              setIsPriceDrop(!isPriceDrop);
            }}
            rightLabel="Notify me when its back in stock"
          ></Switch>
          <Switch
            checked={isAnyColor}
            onChange={() => {
              if (!isAnyColor) {
                setIsAnyColor(true);
                setIsOOS(true);
                setIsPriceDrop(false);
              }
              setIsAnyColor(!isAnyColor);
            }}
            rightLabel="Notify me when any color restocks"
          />
          <Switch
            checked={isPriceDrop}
            onChange={() => {
              setIsOOS(!isOOS);
              setIsPriceDrop(!isPriceDrop);
              setIsAnyColor(false);
            }}
            rightLabel="Notify me when the price drops"
          ></Switch>
        </>
      )}
    </form>
  );
};

LuluLinkInput.defaultProps = {
  user: {},
};

LuluLinkInput.propTypes = {
  user: PropTypes.object,
};

export default LuluLinkInput;
