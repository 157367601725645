import React from "react";
import GenericSection from "./sections/GenericSection";
import SectionHeader from "./sections/partials/SectionHeader";

export default function RefundPolicy() {
  return (
    <GenericSection topDivider className="illustration-section-06">
      <div className="container-xs">
        <SectionHeader data={{ title: "Refund Policy" }} />
        <p>
          If you are at all dissatisfied with our service we will give you a
          refund of your last payment. Just send a message in the chat in the
          lower right of the screen, or email us at getlululemondrops@gmail.com
          (chat is faster).
        </p>
      </div>
    </GenericSection>
  );
}
