import React from "react";
import classNames from "classnames";
import Button from "../components/elements/Button";

const FAQ = () => {
  const outerClasses = classNames("hero section");
  return (
    <section className={outerClasses}>
      <div className="container">
        <h1>FAQ's</h1>
        <h3>When does lululemon restock?</h3>
        <p>
          Usually Tuesdays at 2pm US/Eastern time. But ultimately, they could
          restock at any time, that's why we created an alert system! Sign up to
          get a text as soon as your item is back in stock.
        </p>
        <h3>When does lululemon restock online?</h3>
        <p>
          Usually Tuesdays at 2pm US/Eastern time. But ultimately, they could
          restock at any time, that's why we created an alert system! Sign up to
          get a text as soon as your item is back in stock.
        </p>
        <h3>What time does lululemon restock online?</h3>
        <p>
          Usually Tuesdays at 2pm US/Eastern time. But ultimately, they could
          restock at any time, that's why we created an alert system! Sign up to
          get a text as soon as your item is back in stock.
        </p>
        <h3>Is lululemonDrops legit?</h3>
        <p>
          Yup! We sure are! There have been thousands of successful purchases
          from our alerts. If you are at all dissatisfied with our service we
          will give you your money back.
        </p>
        <h3>How do you keep my data secure?</h3>
        <p>
          For authentication we use{" "}
          <a
            href="https://firebase.google.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Firebase
          </a>
          . The backend server and database (which stores your phone number) is
          hosted on{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://render.com/"
          >
            render.com
          </a>
          . We pay them and they in turn take care of security.
        </p>
        <h3>Is this free?</h3>
        <p>Pretty close to free. The basic package is $5.</p>
        <h3>Why did you make this?</h3>
        <p>
          My wife was spending a ton of time constantly refreshing lululemon's
          website to see if items were in stock. Even getting up early to see if
          it was in stock as well. Now, she just waits for a text and clicks on
          the link in the text and purchases the gear.
        </p>
        <h3>Will you ever sell my data?</h3>
        <p>
          No! To be honest, we wouldn't even know how to do that{" "}
          <span aria-label="emoji" role="img">
            😅
          </span>
          .
        </p>
        <Button tag="a" color="primary" wideMobile href="/signup">
          Sign Up
        </Button>
      </div>
    </section>
  );
};

export default FAQ;
