import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Switch from "../elements/Switch";
import Button from "../elements/Button";
import axios from "axios";

const propTypes = {
  ...SectionTilesProps.types,
  pricingSwitcher: PropTypes.bool,
  pricingSlider: PropTypes.bool,
  onPricingPage: PropTypes.bool,
  stripe: PropTypes.object,
  user: PropTypes.object,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
  pricingSwitcher: false,
  pricingSlider: false,
  onPricingPage: false,
  stripe: {},
  user: {},
};

class Pricing extends React.Component {
  state = {
    priceChangerValue: "0",
    priceInput: {
      // 0: "1,000",
      // 1: "1,250"
    },
    priceOutput: {
      plan1: {
        0: ["", "Free", ""],
        1: ["$", "27", "annually"],
      },
      plan2: {
        0: ["$", "5", "monthly"],
        1: ["$", "47", "annually"],
      },
      plan3: {
        0: ["$", "9", "monthly"],
        1: ["$", "67", "annually"],
      },
    },
  };

  slider = React.createRef();
  sliderValue = React.createRef();

  componentDidMount() {
    if (this.props.pricingSlider) {
      this.slider.current.setAttribute("min", 0);
      this.slider.current.setAttribute(
        "max",
        Object.keys(this.state.priceInput).length - 1
      );
      this.thumbSize = parseInt(
        window
          .getComputedStyle(this.sliderValue.current)
          .getPropertyValue("--thumb-size"),
        10
      );
      this.handleSliderValuePosition(this.slider.current);
    }
  }

  handlePricingSwitch = (e) => {
    this.setState({ priceChangerValue: e.target.checked ? "1" : "0" });
  };

  handlePricingSlide = (e) => {
    this.setState({ priceChangerValue: e.target.value });
    this.handleSliderValuePosition(e.target);
  };

  handleSliderValuePosition = (input) => {
    const multiplier = input.value / input.max;
    const thumbOffset = this.thumbSize * multiplier;
    const priceInputOffset =
      (this.thumbSize - this.sliderValue.current.clientWidth) / 2;
    this.sliderValue.current.style.left =
      input.clientWidth * multiplier - thumbOffset + priceInputOffset + "px";
  };

  getPricingData = (values, set) => {
    return set !== undefined
      ? values[this.state.priceChangerValue][set]
      : values[this.state.priceChangerValue];
  };

  handleSubscriptionClick = async (priceId, planId) => {
    try {
      const res = await axios({
        url: `${process.env.REACT_APP_API}/stripe-checkout`,
        method: "POST",
        data: {
          planId,
          priceId,
          userId: this.props?.user?.uid,
        },
      });
      (await this.props.stripe).redirectToCheckout({
        sessionId: res?.data?.sessionId,
      });
    } catch (e) {
      //TODO: do something with error
    }
  };

  determineBtnName = () => {
    return this.props?.onPricingPage ? "Select" : "Sign up";
  };

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      pricingSwitcher,
      pricingSlider,
      onPricingPage,
      ...props
    } = this.props;

    const outerClasses = classNames(
      !onPricingPage && "pricing section",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "pricing-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "Pricing",
      paragraph: "Easy pricing, powered by Stripe",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              // className="center-content invert-color"
              className="center-content"
            />
            {pricingSwitcher && (
              <div className="pricing-switcher center-content invert-color">
                <Switch
                  checked={this.state.priceChangerValue === "1" ? true : false}
                  onChange={this.handlePricingSwitch}
                  rightLabel="Billed Annually"
                >
                  Billed Monthly
                </Switch>
              </div>
            )}
            {pricingSlider && (
              <div className="pricing-slider center-content invert-color">
                <label className="form-slider">
                  <span className="mb-16">How many users do you have?</span>
                  <input
                    type="range"
                    ref={this.slider}
                    defaultValue={this.state.priceChangerValue}
                    onChange={this.handlePricingSlide}
                  />
                </label>
                <div ref={this.sliderValue} className="pricing-slider-value">
                  {this.getPricingData(this.state.priceInput)}
                </div>
              </div>
            )}
            <div className={tilesClasses}>
              {/* <div
                className={`tiles-item {${
                  !onPricingPage ? "reveal-from-top" : ""
                }}`}
              >

                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-16 mb-24">
                      
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h2 text-color-mid">
                          {this.getPricingData(this.state.priceOutput.plan1, 0)}
                        </span>
                        <span className="pricing-item-price-amount h1">
                          {this.getPricingData(this.state.priceOutput.plan1, 1)}
                        </span>
                      </div>
                      <div className="text-color-low text-xs">
                        {"\n"}
                        {this.getPricingData(this.state.priceOutput.plan1, 2)}
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title fw-500 text-xs text-color-high mb-24">
                        What's included
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">Restock notifications</li>
                        <li className="is-checked">Price drop notifications</li>
                        <li className="is-checked">Watch 1 product</li>
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    <Button
                      tag="a"
                      color="primary"
                      wide
                      href={onPricingPage ? "/profile" : "/signup"}
                    >
                      {this.determineBtnName()}
                    </Button>
                  </div>
                </div>
              </div> */}

              <div
                className={`tiles-item {${
                  !onPricingPage ? "reveal-from-top" : ""
                }}`}
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-16 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h2 text-color-mid">
                          {this.getPricingData(this.state.priceOutput.plan2, 0)}
                        </span>
                        <span className="pricing-item-price-amount h1">
                          {this.getPricingData(this.state.priceOutput.plan2, 1)}
                        </span>
                      </div>
                      <div className="text-color-low text-xs">
                        /month, billed{" "}
                        {this.getPricingData(this.state.priceOutput.plan2, 2)}
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title fw-500 text-xs text-color-high mb-24">
                        What's included
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">Restock notifications</li>
                        <li className="is-checked">Price drop notifications</li>
                        <li className="is-checked">Watch up to 10 products</li>
                        {/* <li className="is-checked">
                          Excepteur sint occaecat velit
                        </li>
                        <li>Excepteur sint occaecat velit</li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    {onPricingPage ? (
                      <Button
                        color="primary"
                        wide
                        onClick={() =>
                          this.handleSubscriptionClick(
                            "price_1J9wS9CeItc4Vb8urHYPvVO8",
                            "prod_JnXXT99ZdxLfqk"
                          )
                        }
                      >
                        {this.determineBtnName()}
                      </Button>
                    ) : (
                      <Button tag="a" color="primary" wide href="/signup">
                        {this.determineBtnName()}
                      </Button>
                    )}
                  </div>
                </div>
              </div>

              <div
                className={`tiles-item {${
                  !onPricingPage ? "reveal-from-top" : ""
                }}`}
                data-reveal-delay="200"
              >
                <div className="tiles-item-inner has-shadow">
                  <div className="pricing-item-content">
                    <div className="pricing-item-header pb-16 mb-24">
                      <div className="pricing-item-price mb-4">
                        <span className="pricing-item-price-currency h2 text-color-mid">
                          {this.getPricingData(this.state.priceOutput.plan3, 0)}
                        </span>
                        <span className="pricing-item-price-amount h1">
                          {this.getPricingData(this.state.priceOutput.plan3, 1)}
                        </span>
                      </div>
                      <div className="text-color-low text-xs">
                        /month, billed{" "}
                        {this.getPricingData(this.state.priceOutput.plan3, 2)}
                      </div>
                    </div>
                    <div className="pricing-item-features mb-40">
                      <div className="pricing-item-features-title fw-500 text-xs text-color-high mb-24">
                        What's included
                      </div>
                      <ul className="pricing-item-features-list list-reset text-xs mb-32">
                        <li className="is-checked">Restock notifications</li>
                        <li className="is-checked">Price drop notifications</li>
                        <li className="is-checked">
                          Watch unlimited products!
                        </li>
                        {/* <li className="is-checked">
                          Excepteur sint occaecat velit
                        </li>
                        <li className="is-checked">
                          Excepteur sint occaecat velit
                        </li> */}
                      </ul>
                    </div>
                  </div>
                  <div className="pricing-item-cta mb-8">
                    {onPricingPage ? (
                      <Button
                        color="primary"
                        wide
                        onClick={() =>
                          this.handleSubscriptionClick(
                            "price_1J9wVlCeItc4Vb8udNVaj3bZ",
                            "prod_JnXaBlENb7uBBX"
                          )
                        }
                      >
                        {this.determineBtnName()}
                      </Button>
                    ) : (
                      <Button tag="a" color="primary" wide href="/signup">
                        {this.determineBtnName()}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;
