import React from "react";
import { withRouter, Switch } from "react-router-dom";
// import ReactGA from "react-ga";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";

// Layouts
import LayoutDefault from "./layouts/LayoutDefault";

// Views
import Home from "./views/Home";
import { PrivacyPolicy } from "./views/PrivacyPolicy";
import SMS from "./views/SMS";
// import BugBounty from "./views/BugBounty";
import Thanks from "./views/Thanks";
import FAQ from "./views/FAQ";
import Signup from "./views/Signup";
import Login from "./views/Login";
import firebase from "firebase/app";
import ThankYou from "./views/ThankYou";
import { Profile } from "./views/Profile";
import ProtectedRoute from "./utils/ProtectedRoute";
import { SelectPlan } from "./views/SelectPlan";
import SelectCountry from "./views/SelectCountry";
import AboutUs from "./views/AboutUs";
// import WhenDoesLuluRestock from "./components/WhenDoesLuluRestock";
import RefundPolicy from "./components/RefundPolicy";
import Database from "./views/Database";
import ProductPage from "./views/ProductPage";
import CategoryPage from "./views/CategoryPage";
// import Blog from "./views/Blog";
// import WhenDoesLuluRestockOnline from "./components/WhenDoesLuluRestockOnline";
// import WhatTimeDoesLuluRestockOnline from "./components/WhatTimeDoesLuluRestockOnline";

// Configure Firebase.
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
firebase.initializeApp(config);

class App extends React.Component {
  state = {
    isSignedIn: false,
    user: {},
    isLoading: true,
  };

  componentDidMount() {
    // ReactGA.initialize("G-9QWFLR909H");
    document.body.classList.add("is-loaded");
    this.refs.scrollReveal.init();
    // Listen to the Firebase Auth state and set the local state.
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        this.setState({ isSignedIn: user?.uid, user, isLoading: false });
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute path="/product">
              <AppRoute
                path="*"
                layout={LayoutDefault}
                component={ProductPage}
              />
            </AppRoute>
            <AppRoute path="/category">
              <AppRoute
                path="*"
                layout={LayoutDefault}
                component={CategoryPage}
              />
            </AppRoute>
            {/* <AppRoute path="/blog" component={Blog} layout={LayoutDefault} /> */}
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute
              exact
              path="/privacy-policy"
              component={PrivacyPolicy}
              layout={LayoutDefault}
            />
            <ProtectedRoute
              exact
              isSignedIn={this.state.isSignedIn}
              isLoading={this.state.isLoading}
              path="/profile"
              user={this.state.user}
              component={Profile}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/sms"
              component={SMS}
              layout={LayoutDefault}
            />
            {/* <AppRoute
              exact
              path="/bug-bounty"
              component={BugBounty}
              layout={LayoutDefault}
            /> */}
            <AppRoute
              exact
              path="/thanks"
              component={Thanks}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              user={this.state.user}
              path="/select-country"
              component={SelectCountry}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/thank-you"
              component={ThankYou}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/about-us"
              component={AboutUs}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/faqs"
              component={FAQ}
              layout={LayoutDefault}
            />
            {/* <AppRoute
              exact
              path="/blog"
              component={WhenDoesLuluRestock}
              layout={LayoutDefault}
            /> */}
            <AppRoute
              exact
              path="/database"
              component={Database}
              layout={LayoutDefault}
            />
            {/* <AppRoute
              exact
              path="/when-does-lululemon-restock-online"
              component={WhenDoesLuluRestockOnline}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              path="/what-time-does-lululemon-restock-online"
              component={WhatTimeDoesLuluRestockOnline}
              layout={LayoutDefault}
            /> */}
            <AppRoute
              exact
              path="/refund-policy"
              component={RefundPolicy}
              layout={LayoutDefault}
            />
            <ProtectedRoute
              exact
              path="/select-plan"
              user={this.state.user}
              isSignedIn={this.state.isSignedIn}
              isLoading={this.state.isLoading}
              component={SelectPlan}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              isSignedIn={this.state.isSignedIn}
              setUser={(user) =>
                this.setState({ isSignedIn: !!user, user, isLoading: false })
              }
              path="/signup"
              component={Signup}
              layout={LayoutDefault}
            />
            <AppRoute
              exact
              isSignedIn={this.state.isSignedIn}
              path="/login"
              component={Login}
              layout={LayoutDefault}
            />
          </Switch>
        )}
      />
    );
  }
}

export default withRouter((props) => <App {...props} />);
