import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Team extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "team section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "team-inner section-inner",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "About me",
      paragraph: `This website all got started because my buddy was trying to get some lululemon leggings for his wife. 2 weeks later I had a working prototype and she had her leggings. Then put up a website for it and here we are today. I'm a software engineer and recently quit my job to pursue my dream to make it as an entrepreneur! Currently building a few projects, trying to make enough money to pay rent 😅 If you need help with anything, or have another brand you want restock alerts for, or want another piece of software built, please contact me! I'm always available via the chat in the lower right corner of the screen.`,
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content reveal-from-top"
            />
            <div className={tilesClasses}>
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div
                    className="team-item-header reveal-from-top"
                    data-reveal-container=".tiles-item"
                  >
                    <div className="team-item-image mb-24 illustration-element-07">
                      <div>
                        <img
                          src={require("./../../assets/images/me.jpg")}
                          alt="me"
                          style={{
                            width: "10rem",
                            height: "10rem",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="team-item-content reveal-from-top"
                    data-reveal-container=".tiles-item"
                    data-reveal-delay="200"
                  >
                    <h5 className="team-item-name mt-0 mb-4">Adrian</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;
