import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";
import { Link } from "react-router-dom";

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class FeaturesTiles extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      "features-tiles section center-content",
      topOuterDivider && "has-top-divider",
      bottomOuterDivider && "has-bottom-divider",
      hasBgColor && "has-bg-color",
      invertColor && "invert-color",
      className
    );

    const innerClasses = classNames(
      "features-tiles-inner section-inner",
      "reveal-from-top",
      topDivider && "has-top-divider",
      bottomDivider && "has-bottom-divider"
    );

    const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

    const sectionHeader = {
      title: "How it works",
      paragraph: "Super simple, do it all from your phone",
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              data={sectionHeader}
              className="center-content reveal-from-top"
              width={72}
              height={72}
            />
            <div className={tilesClasses}>
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image mb-16 reveal-from-top"
                      data-reveal-container=".tiles-item"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-01.svg")}
                        alt="Features tile icon 01"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="100"
                    >
                      Step 1: Sign Up
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="200"
                    >
                      Just go to our <Link to="/signup">sign up page</Link> to
                      get started.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image mb-16 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="100"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-02.svg")}
                        alt="Features tile icon 02"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="200"
                    >
                      Step 2: Send us a link
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="300"
                    >
                      Simply send us a link and we'll text you when it's back in
                      stock!
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <div className="features-tiles-item-header">
                    <div
                      className="features-tiles-item-image mb-16 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="200"
                    >
                      <Image
                        src={require("./../../assets/images/feature-tile-icon-03.svg")}
                        alt="Features tile icon 06"
                        width={72}
                        height={72}
                      />
                    </div>
                  </div>
                  <div className="features-tiles-item-content">
                    <h4
                      className="mt-0 mb-8 reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="300"
                    >
                      Step 3: Sit back and relax!
                    </h4>
                    <p
                      className="m-0 text-sm reveal-from-top"
                      data-reveal-container=".tiles-item"
                      data-reveal-delay="400"
                    >
                      Our bots run 24/7 so sit back and relax, we'll do the
                      rest!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div
              style={{
                paddingBottom: "55%",
                paddingTop: 30,
                position: "relative",
                overflow: "hidden",
              }}
            >
              <iframe
                loading="lazy"
                style={{ position: "absolute", top: 0, left: 0 }}
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/ycmoR6nchPc"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded youtube"
              />
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
