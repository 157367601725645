import React from "react";
import { Route } from "react-router-dom";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isSignedIn,
  user,
  isLoading,
  setUser,
  ...rest
}) => {
  Layout =
    Layout === undefined
      ? (props) => <React.Fragment>{props.children}</React.Fragment>
      : Layout;
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout isLoading={isLoading} user={user} isSignedIn={isSignedIn}>
          <Component
            {...props}
            isSignedIn={isSignedIn}
            user={user}
            setUser={setUser}
          />
        </Layout>
      )}
    />
  );
};

export default AppRoute;
