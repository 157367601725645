import React, { useState } from "react";
import classNames from "classnames";
import Select from "../components/elements/Select";
import Button from "../components/elements/Button";
import axios from "axios";

const SelectCountry = (props) => {
  const outerClasses = classNames("hero section");

  const [country, setCountry] = useState("US");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API}/saveUserCountryCode`,
        data: {
          phone: props?.user?.phoneNumber,
          countryCode: country,
        },
      });
      window.location.href = "/select-plan";
    } catch (error) {
      console.log("error", error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <section className={outerClasses}>
      <div className="container">
        <h1>Select Country</h1>
        <p style={{ color: "red" }}>{error}</p>
        <form onSubmit={onSubmit}>
          <Select
            required
            status={error && "error"}
            onChange={(e) => setCountry(e.target.value)}
            value={country}
            label="Select Country"
          >
            <option hidden>Choose what country you're in</option>
            <option value="US">US</option>
            <option value="CA">Canada</option>
          </Select>
          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button loading={isLoading} color="primary" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default SelectCountry;
